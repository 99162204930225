<template>
    <!-- <router-link to="/home">Home</router-link>
    <router-link to="/about">About</router-link> -->

    <router-view></router-view>
     

</template>

<style lang="less">
</style>

<!-- 移除右侧滚动条 -->
<style lang="scss">
// ::-webkit-scrollbar {
//   width: 0 !important;
// }
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
</style>

