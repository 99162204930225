<template>
  <div class="h-100 ding-text" style="display: flex; flex-direction: column;">
    <div style="flex: 0;">
      <Top :commonResponse="commonResponseData"></Top>
    </div>

    <!-- nohup java -Djasypt.encryptor.password=? -jar ?.jar & -->

    <div class="container text-center" style="flex: 1;">
      <div class="row justify-content-center" style="height:100%">
        <div class="col-6 align-self-center">

            <main class="px-3">
              <h1>Hi！Welcome to 164office!</h1>
              <p class="lead" id="revealText"></p>
              <p class="lead">
                <a href="/updateLog" class="btn btn-lg btn-light fw-bold border-black bg-white">更新日志</a>
              </p>
            </main>

        </div>
      </div>
    </div>


    <div style="flex: 0;">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col">
            <footer class="mt-auto text-black-50">
              <p><span class="text-black fw-bold">164office</span> All Rights Reserved. 备案号: 鄂ICP备2022008452号
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>

  <!-- <div class="cover-container d-flex w-100 p-3 mx-auto flex-column bg-dark">
    <main class="px-3 h-100">
      <h1>Cover your page.</h1>
      <p class="lead">Cover is a one-page template for building simple and beautiful home pages. Download, edit the
        text, and add your own fullscreen background photo to make it your own.</p>
      <p class="lead">
        <a href="#" class="btn btn-lg btn-light fw-bold border-white bg-white">Learn more</a>
      </p>
    </main>

    <footer class="mt-auto text-white-50">
      <p>Cover template for <a href="https://getbootstrap.com/" class="text-white">Bootstrap</a>, by <a
          href="https://twitter.com/mdo" class="text-white">@mdo</a>.</p>
    </footer>
  </div> -->

    <!-- <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <div class="card text-center">
            <div class="card-header">
              <h4>首页通知!</h4>
            </div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p>{{homeNotice.v}}</p>
                <footer class="blockquote-footer">{{homeNotice.updateTimeStr}}</footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="alert alert-success text-center mb-0" role="alert">
        <h5>平台日志</h5>
      </div>
      <div class="alert alert-success" role="alert" v-for="updateLog in updateLogList" :key="updateLog.id">
        <h4 class="alert-heading">{{ updateLog.note }}</h4>
        <p style="white-space: pre-wrap;">{{ updateLog.text }}</p>
        <hr />
        <p class="card-text" v-if="updateLog.note != 'undefined'">{{ updateLog.createTimeStr }}</p>
      </div>
      <Page :commonPage="page" @commonPageChange="commonPageChange($event)"></Page>
      <hr />
    </div> -->

    <div class="live2dModel" style="cursor: pointer;">
      <canvas ref="liveCanvas" @click="action()"></canvas>
    </div>
    
  </div>
</template>


<script>
import { Popover } from "bootstrap";
import Page from '@/components/Page.vue';
import Top from "@/components/Top.vue";
import { searchUpdateLog } from "../api/updateLog";
import { saveVisitLog } from "../api/welcome";
import { getPublicKey, encrypt,decrypt,getBrowserType } from "@/api/common";
import { getHomeNotice } from "../api/systemConfig";

import { ref } from 'vue';

// 在需要使用 Live2D 的组件中引入
import vueLive2d from 'vue-live2d'
import * as PIXI from 'pixi.js'
import { Live2DModel } from "pixi-live2d-display";  // .model.json
// import { Live2DModel } from "pixi-live2d-display/cubism4";  // .model3.json

window.PIXI = PIXI; // 为了pixi-live2d-display内部调用
let app; // 为了存储pixi实例
let model; // 为了存储live2d实例

export default {
  name: "home",
  components: {
    Top,
    Page,
    vueLive2d  // 注册 Vue-live2d 组件
  },
  data() {
    return {
      page: {
        size: 10,
        currentPage: 1, // 偏移量,数据库从0开始
        totalPage: 0,
      },
      commonResponseData: { // Modal 弹窗数据
        success: true,
        msg: '',
      },
      updateLogList: [],
      visitLogInfo: {},
      publicKey: '',
      privateKey: '',
      ip: '',
      homeNotice: {},
      intervalId: null, // 用于存储 setInterval 的 ID

      lock: false,  // 锁,防止重复点击触发 aya 语音
      audioContext: {},
      dataArray: {},
      timeFlag: 0,  // 用于限制频繁点击 live2d
      models: [ {name:'aya',x:200,y:90},{name:'yifu',x:200,y:120},{name:'qiansheng',x:200,y:90},{name:'huayin',x:200,y:90},{name:'saya',x:200,y:95} ],
      modelIndex:0,
    }
  },
  //需要引入
  beforeUnmount() {
    model?.destroy();
    app?.destroy();
  },
  async mounted() {
    this.page.currentPage = 1;
    this.searchUpdateLog();
    saveVisitLog(Object.assign({key:0}));
    getHomeNotice().then(
      response => {
        this.homeNotice = response.data.data;
      }
    );
    // this.revealText();  // 方法一
    this.showText();  // 方法二
    document.title = "ding！office！";

    this.modelIndex = Math.floor(Math.random() * 5);
    // this.modelIndex = 0;

    this.audioContext = new AudioContext();
    app = new PIXI.Application({
      view: this.$refs.liveCanvas,  // ref 组件绑定, liveCanvas 为下文自定义的
      autoStart: true,  // 是否开启自动播放
      resizeTo: window,
      backgroundAlpha: 0,  //透明度
    });
    // 这里是放 live2d 资源的地方, 直接相对路径引用即可
    // model = await Live2DModel.from("../aya/aya.model.json");
    // model =await Live2DModel.from("../aya/aya.model.json", { autoInteract: true });
    // model = await Live2DModel.from("../aya/aya.model.json", { autoInteract: false });  // 注意 .model.json 与 .model3.json 所导入的包不一样
    // model =await  Live2DModel.from("https://cdn.jsdelivr.net/gh/guansss/pixi-live2d-display/test/assets/haru/haru_greeter_t03.model3.json");

    // document.getElementById("aya").addEventListener('pointermove', (event) => model.focus(event.clientX, event.clientY));  // 绑定追踪鼠标事件,仅鼠标在画布内有效
    // document.getElementById("aya").addEventListener('pointerdown', (event) => model.tap(event.clientX, event.clientY));  // 绑定点击事件

    model =await Live2DModel.from('../'+this.models[this.modelIndex].name+'/'+this.models[this.modelIndex].name+'.model.json',{ autoInteract: this.modelIndex!=0 });

    app.stage.addChild(model);


    model.x=this.models[this.modelIndex].x;  // 设置模型在画布中的位置
    model.y=this.models[this.modelIndex].y;  // 设置模型在画布中的位置
    model.scale.set(0.2);  // 调整缩放比例,0.1-0.2整体比较合适
    // this.$refs.liveCanvas.width = "700";  // 画布大小,必须最后设置,否则会被某些原因重置
    // this.$refs.liveCanvas.height = "670";

    if (this.modelIndex!=0) {
      model.motion('act');
      // model.motion('init');
    }
  },
  updated() {  // 更新之后.场景:获取更新真实DOM之后
    /**
     * 尝试一下放在 updated() 和 mounted() 中分别有什么区别
     */
    var popoverTriggerList = Array.prototype.slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl)
    });
  },
  methods: {
    commonPageChange(event) { // 通用分页
      this.page = event;
      this.searchUpdateLog();
    },
    searchUpdateLog() {
      searchUpdateLog(Object.assign(this.page)).then(
        response => {
          this.updateLogList = response.data.data.data;
          this.page.totalPage = response.data.data.totalPage;
        }
      )
    },
    revealText() {
      if (!this.intervalId) {
        this.intervalId = setInterval(this.customFunction, 100); // 每秒执行一次
      }
    },
    customFunction() {
      const text = "这是一个普通的个人平台，在这里你可以写写博客，听听歌，在线聊天，玩玩小游戏，此外还包含简易记账本，以及BS数据参考";
      const textElement = document.getElementById("revealText");
      var len = textElement.innerHTML==null?"":textElement.innerHTML.length;
      if (len<text.length) {
        textElement.innerHTML = textElement.innerHTML+text[len];
      }
      else {
        clearInterval(this.intervalId);
      }
    },
    showText() {
      const text = "这是一个普通的个人平台，在这里你可以写写博客，听听歌，在线聊天，玩玩小游戏，此外还包含简易记账本，以及BS数据参考";
      const textElement = document.getElementById("revealText");
      var len = textElement.innerHTML.length;  // 获取以显示的文本长度
      if (len<text.length) {  // 比较总长度
        textElement.innerHTML = textElement.innerHTML + text[len];
        setTimeout(() => {
          this.showText();
        }, 50);
      }
    },
    //  动作与对应的语音太难找
    //  而且导入vue后声音嘴型同步失效, 需要额外执行代码更改参数,但似乎动作控制的嘴部优先级更高,因为只有动作执行完毕后才由代码控制嘴型
    //  而且后置动作失效
    //  所以将语音和动作分开执行,但会出现角色讲话无动作表情
    action() {
      // this.$refs.liveCanvas.addEventListener('pointermove', (event) => model.focus(event.clientX, event.clientY));
      // this.$refs.liveCanvas.addEventListener('pointerdown', (event) => model.tap(event.clientX, event.clientY));
      // model.motion("额头");

      if (this.modelIndex==0) {  // 目前仅支持 aya 有交互效果,其他角色仅视线跟随鼠标
        console.log("timeFlag:" + (Date.now() - this.timeFlag) / 1000);
        const num = Math.random();
        console.log("num:"+num);
        if (num > 0.58) {  // 仅触发语音
          if (Date.now() - this.timeFlag > 5 * 1000) {
            this.timeFlag = Date.now();
            console.log("触发语音! lock:" + this.lock);
            if (!this.lock) {
              this.lock = true;
              var audioFile = './aya/sound/' + Math.floor(Math.random() * 13) + '.mp3';
              console.log("audioFile:"+audioFile);
              this.speaking(audioFile);
            }
          }
          else {
            console.log("频繁操作!");
          }
        }
        else {  // 仅触发动作
          if (Date.now() - this.timeFlag > 10 * 1000) {
            this.timeFlag = Date.now();
            console.log("触发动作!");
            model.motion('web'+Math.floor(Math.random() * 13));
            setTimeout(this.initModel, 10000);
          }
          else {
            console.log("频繁操作!");
          }
        }
      }

    },
    initModel() {
      console.log("恢复状态");
      model.motion('init');
    },
    async speaking(audioFile) {
      const response = await fetch(audioFile);
      const audioData = await response.arrayBuffer();
      const audioBuffer = await this.audioContext.decodeAudioData(audioData);
      const source = this.audioContext.createBufferSource();
      const analyser = this.audioContext.createAnalyser();
      source.buffer = audioBuffer;
      analyser.connect(this.audioContext.destination);
      source.connect(analyser);

      var that = this;
      source.onended = function() {
        that.lock = false;
        console.log('Audio playback has ended.   lock:'+that.lock);
      };

      source.start();
      // source.volume = 0;

      const updateMouth = () => {
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
        const mouthOpen = Math.min(1, volume / 100);
        model.internalModel.coreModel.setParamFloat('PARAM_MOUTH_OPEN_Y', mouthOpen);
        if (this.audioContext.state !== 'close') {
          requestAnimationFrame(updateMouth);
        }
      }
      updateMouth();
    },
  },
};
</script>

<style>
.fade-in-text {
  opacity: 0; /* 初始状态为透明 */
  transition: opacity 2s ease-in-out; /* 过渡效果，透明度在2秒内平滑变化 */
}
/* 设置canvas的悬浮样式 */
.live2dModel {
  
    position: fixed;  /* 使用fixed定位使canvas悬浮 */
    right: 0;  /* 必须设值,用于定位画布在页面中的位置 */
    bottom: 0;  /* 必须设值,用于定位画布在页面中的位置 */
    
    width: 700px;
    height: 670px;

    /* 确保canvas在其他内容之上 */
    z-index: 1000;
}
</style>